<template>
  <div class="gestion-aaf">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style label-box-style-w-1 p-4-5">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select b-form-select-new-style-w-1"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select  "
            v-model="filterMonth"
            :options="[
              { value: null, text: 'Tous' },
              ...computedGetMonthForYearTh
            ]"
            @change="handleChangeMonth"
            text-field="text"
            value-field="value"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  w-63-px">
            <span class="title-tabel">Dépôt</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select  "
            v-model="filterDepot"
            :options="[{ value: null, text: 'Tous' }, ...computedDepot]"
            text-field="text"
            value-field="value"
            @change="handleChangeDepot"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style label-box-style-w-1">
            <span class="title-tabel">Régie</span>
          </div>

          <SelectComponent
            :options="computedgetAllRegie"
            :value="filterRegie"
            label="name"
            champName="régie"
            filterName="filterRegie"
            :change="handleChange"
            :track-by="'name'"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style bg-select-vue "
          />
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style label-box-style-w-1">
            <span class="title-tabel">statut</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select"
            v-model="filterStatu"
            :options="validationList"
            value-field="value"
            text-field="text"
            @change="handleChangeStatue"
          ></b-form-select>
        </div>
        <div v-if="getChargementFactureRegieAaf" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="body-box-rapport "
      :class="{ 'resizing-body-box-rappot': computeAdminRegie }"
    >
      <palatte-color />
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style customTableGestionRegieAdmin table-header"
          :items="computedRegieFacture"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:cell(details)="data">
            <button class="btn" @click="data.toggleDetails">
              <font-awesome-icon
                class="icon-plus"
                icon="plus-square"
                v-if="data.detailsShowing == false"
              />
              <font-awesome-icon
                class="icon-plus"
                icon="minus-square"
                v-if="data.detailsShowing == true"
              />
            </button>
          </template>
          <template v-slot:cell(regie)="data">
            {{ data.item.regie.name }}
          </template>

          <!-- <template v-slot:cell(pay_previsite)="data">
            <span v-if="data.item.pay_previsite == 1">
              <font-awesome-icon icon="check-circle" class="statu" />
            </span>
            <span v-else>
              <font-awesome-icon :icon="['far', 'circle']" class="statu" />
            </span>
          </template> -->

          <template v-slot:cell(statue)="data">
            <EditableInput
              :editable="!computeAdminRegie && cantValid($options.name)"
              champName="statue"
              editableType="select"
              :optionsSelect="computedValidationList"
              :item="data.item"
              :value="data.item.statue"
              :updateFunction="validationAafRegie"
              label="value"
              :positionSelect="true"
              formate="regieFacture"
            />
          </template>
          <template v-slot:cell(total)="data">
            {{ data.item.total }} €
          </template>
          <template v-slot:cell(payed_at)="data">
            <EditableInput
              :editable="cantUpdate($options.name) && !computeAdminRegie"
              champName="payed_at"
              :item="data.item"
              :value="data.item.payed_at"
              type="datetime"
              editableType="datetime"
              :updateFunction="payedAafeRegie"
            />
          </template>
          <template v-slot:cell(comment)="data">
            <template v-if="computeAdminRegie">{{
              data.item.comment
            }}</template>
            <commentInput
              v-else
              :item="data.item"
              :value="data.item.comment"
              :updateFunction="newCommentRegieAaf"
            />
          </template>

          <template v-slot:cell(facture_uploaded)="data">
            <b-button
              v-if="
                computedCantExport &&
                  data.item.file != null &&
                  (data.item.statue == 'encore de paiement' ||
                    data.item.statue == 'facture valid' ||
                    data.item.statue == 'facture refuse' ||
                    data.item.statue == 'paye' ||
                    data.item.statue == 'encore de verification')
              "
              size="sm"
              class="iconDownload mr-1"
              title="download facture"
              @click.prevent.stop="download(data.item)"
            >
              <font-awesome-icon icon="download" />
            </b-button>
            <b-button
              v-if="
                data.item.file != null &&
                  (data.item.statue == 'encore de paiement' ||
                    data.item.statue == 'facture valid' ||
                    data.item.statue == 'facture refuse' ||
                    data.item.statue == 'paye' ||
                    data.item.statue == 'encore de verification')
              "
              size="sm"
              class="iconDownload"
              title="open facture"
              @click.prevent.stop="visualisation(data.item)"
            >
              <font-awesome-icon icon="eye" />
            </b-button>
          </template>
          <template v-slot:cell(download)="data">
            <b-button
              v-if="computedCantExport"
              size="sm"
              class="iconDownload"
              title="Appel à facturation"
              @click.prevent.stop="
                downloadAppelFacturation({
                  data: data.item
                })
              "
            >
              <font-awesome-icon icon="download" />
            </b-button>
          </template>
          <template v-slot:cell(upload)="data">
            <div
              class="container"
              @drop="handleFileDrop($event, data.item)"
              v-if="
                data.item.statue !== 'paye' &&
                  data.item.statue !== 'encore de paiement' &&
                  data.item.statue != 'facture valid'
              "
            >
              <div class="file-wrapper" title="Upload facture">
                <input
                  type="file"
                  name="file-input"
                  accept="application/pdf,application/vnd.ms-excel"
                  @change="handleFileInput($event, data.item)"
                />
                <div class="upload_label" v-if="!data.item.file">
                  <font-awesome-icon icon="cloud-upload-alt" />
                </div>
                <div class="upload_label" v-if="data.item.file">
                  <font-awesome-icon
                    icon="file-pdf"
                    :pulse="getLoadingUploadFactureRegieTh"
                  />
                </div>
              </div>
            </div>
          </template>
          <template #row-details="data">
            <template v-if="data.item.depots">
              <div
                class="body-task mb-2"
                v-for="(depotDetails, index) in data.item.depots"
                :key="'depot' + index"
              >
                <div class="align-td-item">
                  <td class="col-plus item-details-style mr-3 ">
                    <font-awesome-icon
                      class="caret-down ml-3 mr-5 "
                      :icon="
                        depotDetails.show == false
                          ? 'caret-right'
                          : 'caret-down'
                      "
                      @click="depotDetails.show = !depotDetails.show"
                    />
                  </td>
                  <td class="item-details-style justify-content-sb">
                    <div><b>Numéro de dépôt </b></div>
                  </td>
                  <td class="item-details-style ">
                    <div>{{ depotDetails.name }}</div>
                  </td>
                  <td class="item-details-style ">
                    <font-awesome-icon
                      icon="arrow-alt-circle-down"
                      class="icon ml-2"
                      @click="
                        downloadAppelFacturation({
                          data: data.item,
                          depot: depotDetails.name
                        })
                      "
                    />
                  </td>

                  <td class="item-details-style justify-content-sb">
                    <div><b>Total </b></div>
                    <div></div>
                  </td>
                  <td class="item-details-style ">
                    {{ depotDetails.total }} €
                  </td>
                  <td class="item-details-style "></td>
                  <td class="item-details-style "></td>
                  <td class="item-details-style "></td>
                  <td class="item-details-style "></td>
                </div>

                <template v-if="depotDetails.show && depotDetails.show == true">
                  <div class="details-num-dossier">
                    <div class="align-td-item">
                      <td class="item-details-style num-dossier">
                        <b>Numéro dossier</b>
                      </td>

                      <td class="item-details-style "><b>Nom </b></td>

                      <td class="item-details-style "><b>Prénom</b></td>
                      <td class="item-details-style "><b>Montant min</b></td>
                    </div>
                    <div
                      class="align-td-item"
                      v-for="(task, indextask) in depotDetails.tasks"
                      :key="'depot' + depotDetails.name + 'tasks' + indextask"
                    >
                      <td class="item-details-style ">
                        {{ task.numero_dossier }}
                      </td>

                      <td class="item-details-style ">{{ task.nom1 }}</td>
                      <td class="item-details-style ">{{ task.prenom1 }}</td>

                      <td class="item-details-style ">
                        {{ task.montant_final }} €
                      </td>
                    </div>
                  </div>

                  <div
                    class="border-botom"
                    v-if="
                      data.item.depots && index < data.item.depots.length - 1
                    "
                  ></div>
                </template>

                <div
                  class="border-botom"
                  v-if="data.item.depots && index < data.item.depots.length - 1"
                ></div>
              </div>
            </template>
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowFactureRegieAaf"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'Gestion-des-AAF-régie-Th',
  data() {
    return {
      filterRegie: { name: 'tous', id: null },
      filterStatu: null,
      ShowALL: false,
      filteryear: null,
      filterDepot: null,
      filterMonth: null,
      filterPrecarite: null,
      filterChauffage: null,
      filterZone: null,
      ListYear: [],
      page: 1,
      perPage: 10,
      fields: [
        {
          key: 'details',
          label: '',
          thClass: 'th-color',
          tdClass: 'color'
        },
        {
          key: 'regie',
          label: 'Nom',
          thClass: 'th-name-regie',
          tdClass: 'td-name-regie'
        },

        { key: 'total', label: 'Total' },

        // {
        //   key: 'pay_previsite',
        //   label: 'Payer previsite',
        //   thClass: 'thPayPrevisite'
        // },
        { key: 'total', label: 'Total' },

        { key: 'comment', label: 'Commentaire' },

        {
          key: 'download',
          label: 'Appel à  facturation'
        },
        { key: 'facture_uploaded', label: 'Facture' },
        { key: 'upload', label: 'Upload facture' },
        {
          key: 'payed_at',
          label: 'Date de paiement'
          // thClass: 'thvalidationR',
          // tdClass: 'tdvalidationR'
        },
        {
          key: 'statue',
          label: 'Statut'
          // thClass: 'thpaiementR',
          // tdClass: 'tdpaiementR'
        }
      ],
      validationList: [
        { value: null, text: 'tous' },
        { value: 'en attend', text: 'En cours de validation' },
        { value: 'refuse', text: 'Réfusé' },
        { value: 'valid', text: 'appel à facturation validé' },
        {
          value: 'encore de verification',
          text: 'En cours de vérification'
        },
        { value: 'facture valid', text: ' facture validé' },
        { value: 'facture refuse', text: ' facture réfusé' },

        { value: 'encore de paiement', text: 'En cours de paiement' },
        { value: 'paye', text: 'Payé' }
      ],
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ]
    };
  },
  methods: {
    ...mapActions([
      'getAafRegie',
      'newCommentRegieAaf',
      'validationAafRegie',
      'DownloadingAafRegieBill',
      'payedAafeRegie',
      'downloadAafRegiePDF',
      'featchDepotForYearTH',
      'fetchMonthForYearTH',
      'fetchRegieWithFactureGenerateTh',
      'uploadFactureRegieAafTh'
    ]),
    showAllDetails() {
      this.computedRegieFacture.forEach(item => {
        this.$set(item, '_showDetails', true);
      });
      this.ShowALL = !this.ShowALL;
    },
    hideAllDetails() {
      this.computedRegieFacture.forEach(item => {
        this.$set(item, '_showDetails', false);
      });
      this.ShowALL = !this.ShowALL;
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.statue == 'refuse') return 'ligneRefuse';
      else if (item.statue == 'valid') return 'ligneAccepted';
      else if (item.statue == 'encore de verification')
        return 'ligneVerification';
      else if (item.statue == 'facture valid') return 'ligneValidF';
      else if (item.statue == 'facture refuse') return 'ligneRefuseF';
      else if (item.statue == 'encore de paiement') return 'ligneEnAttend';
      else if (item.statue == 'paye') return 'lignePaye';
      else return 'ligneEnCourDeValidation';
    },
    handleChange(payload) {
      if (payload) {
        this[payload.champName] = payload.value;
      }
      this.page = 1;
      sessionStorage.setItem('page-gestion-aaf', this.page);
      this.setLocalStorge();
      this.handleFilter();
    },
    handleChangeStatue() {
      this.fetchAllRegieActiveInSuiviRegieTh({
        year: this.filteryear,
        month: this.filterMonth,
        statue: this.filterValidation
      });
      this.setLocalStorge();
      this.handleFilter();
    },
    handleChangeYear() {
      this.setLocalStorge();
      this.page = 1;
      sessionStorage.setItem('page-gestion-aaf', this.page);
      this.filterMonth = null;
      this.filterDepot = null;
      this.filterRegieAction();
      this.fetchMonthForYearTH(this.filteryear);
      this.featchDepotForYearTH(this.filteryear);

      this.handleFilter();
    },
    filterRegieAction() {
      this.fetchRegieWithFactureGenerateTh({
        month: this.filterMonth,
        year: this.filteryear
      });
    },
    pagination(paginate) {
      this.page = paginate;
      sessionStorage.setItem('page-gestion-aaf', this.page);
      this.handleFilter();

      this.ShowALL = false;
    },
    changePerPage() {
      this.page = 1;
      sessionStorage.setItem('page-gestion-aaf', this.page);
      this.handleFilter();

      this.ShowALL = false;
    },
    download(item) {
      if (item.file != null) {
        this.DownloadingAafRegieBill(item);
      }
    },
    visualisation(item) {
      window.open(item.file.path, '_blank');
    },
    downloadAppelFacturation(item) {
      this.downloadAafRegiePDF({
        item: item.data,
        depot: item.depot ? item.depot : null
      });
    },
    handleChangeMonth() {
      this.filterDepot = null;
      this.filterRegieAction();
      this.setLocalStorge();
      this.handleFilter();
    },
    handleChangeDepot() {
      this.setLocalStorge();

      this.handleFilter();
    },
    handleFilter() {
      this.getAafRegie({
        year: this.filteryear,
        statu: this.filterStatu,
        filterRegie: this.filterRegie,
        page: this.page,
        per_page: this.perPage,
        month: this.filterMonth,
        depot: this.filterDepot
      });
    },
    setLocalStorge() {
      localStorage.setItem(
        'gestion-aaf',
        JSON.stringify({
          filteryear: this.filteryear,
          filterStatu: this.filterStatu,
          filterRegie: this.filterRegie,
          filterMonth: this.filterMonth,
          filterDepot: this.filterDepot
        })
      );
    },
    handleFileDrop(e, item) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach(f => {
        item.file = f;
      });
      this.uploadFactureRegieAafTh(item);
    },
    async handleFileInput(e, item) {
      let files = e.target.files;
      files = e.target.files;
      if (!files) return;
      [...files].forEach(f => {
        item.file = f;
      });
      const responce = await this.uploadFactureRegieAafTh(item);
      if (responce) {
        this.$alert('', 'facture uploadé', 'success');
      } else {
        this.$alert(this.getErrorUploadFactureRegieTh, '', 'error');
      }
    }
  },

  computed: {
    ...mapGetters([
      'getChargementFactureRegieAaf',
      'getTotalRowFactureRegieAaf',
      'getRegieFactureAaf',
      'getErrorFactureAaf',
      'getRegieWithFactureGenerateTh',
      'cantExport',
      'cantValid',
      'cantUpdate',
      'getMonthForYearTh',
      'getAllDepotForYearTh',
      'getUserData',
      'getLoadingUploadFactureRegieTh',
      'getErrorUploadFactureRegieTh'
    ]),
    computeAdminRegie: function() {
      return this.getUserData && this.getUserData.role === 'admin_regie';
    },
    computedFields() {
      if (this.getUserData && this.getUserData.role === 'admin_regie') {
        return this.fields.filter(item => item.key != 'facture_uploaded');
      } else {
        return this.fields.filter(item => item.key != 'upload');
      }
    },
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    },
    computedDepot() {
      let tab = [];
      if (this.filterMonth == null) {
        tab = this.getAllDepotForYearTh.map(item => ({
          value: item.depot,
          text: item.depot
        }));
        return tab;
      } else {
        let table = this.getMonthForYearTh.filter(
          item => item.month_name == this.filterMonth
        );
        if (table.length) {
          tab = table[0].depots.map(item => ({
            value: item.depot,
            text: item.depot
          }));
          return tab;
        } else {
          return [];
        }
      }
    },
    computedCantExport() {
      return this.cantExport(this.$options.name);
    },
    computedRegieFacture() {
      if (this.filterStatu != null) {
        return this.getRegieFactureAaf.filter(
          row => row.statue == this.filterStatu
        );
      }
      return this.getRegieFactureAaf;
    },
    computedgetAllRegie() {
      return [
        { id: null, name: 'tous' },
        ...this.getRegieWithFactureGenerateTh
      ];
    },
    computedValidationList() {
      return [
        {
          value: 'en attend',
          full_name: 'En cours de validation',
          $isDisabled: true
        },
        { value: 'refuse', full_name: 'Réfusé' },
        { value: 'valid', full_name: 'Appel à facturation validé' },
        {
          value: 'encore de verification',
          full_name: 'En cours de vérification'
        },
        { value: 'facture valid', full_name: 'Facture validé' },
        { value: 'facture refuse', full_name: 'Facture réfusé' },
        { value: 'encore de paiement', full_name: 'En cours de paiement' },

        { value: 'paye', full_name: 'Payé' }
      ];
    }
  },

  components: {
    commentInput: () => import('../../component/CommentInput.vue'),
    SelectComponent: () => import('../../SelectComponent.vue'),
    palatteColor: () => import('../../component/paletteColor.vue'),
    EditableInput: () => import('../../component/EditableInput.vue')
  },
  mounted() {
    var courantYear = moment().year();
    if (localStorage.getItem('gestion-aaf')) {
      this.filteryear = JSON.parse(
        localStorage.getItem('gestion-aaf')
      ).filteryear;
      this.filterMonth = JSON.parse(
        localStorage.getItem('gestion-aaf')
      ).filterMonth;
      this.filterDepot = JSON.parse(
        localStorage.getItem('gestion-aaf')
      ).filterDepot;
      this.filterStatu = JSON.parse(
        localStorage.getItem('gestion-aaf')
      ).filterStatu;
      this.filterRegie = JSON.parse(
        localStorage.getItem('gestion-aaf')
      ).filterRegie;
    } else {
      this.filteryear = courantYear;
      this.setLocalStorge();
    }
    if (sessionStorage.getItem('page-gestion-aaf')) {
      this.page = sessionStorage.getItem('page-gestion-aaf');
    } else {
      sessionStorage.setItem('page-gestion-aaf', this.page);
    }
    this.fetchMonthForYearTH(this.filteryear);
    this.featchDepotForYearTH(this.filteryear);

    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }

    this.filterRegieAction();
    this.handleFilter();
  }
};
</script>
<style lang="scss" scoped>
.gestion-aaf {
  padding: 10px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;

  .chargement {
    font-size: 9px;
    .spinner-border {
      height: 21px;
      width: 21px;
    }
  }
  .body-box-rapport {
    height: calc(100vh - 174px);
    .customTableGestionRegieAdmin {
      max-height: calc(100vh - 210px) !important;
      margin-bottom: 0px;
    }
  }
  .resizing-body-box-rappot {
    height: calc(100vh - 106px);
    .customTableGestionRegieAdmin {
      max-height: calc(100vh - 180px) !important;
      height: calc(100vh - 180px) !important;
    }
  }
  .statu {
    font-weight: 600;
    color: #479a57;
  }
  .checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #858585;
    color: #858585;
    border-radius: 2px;
    font-size: 11px;
    align-items: center;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .checked {
    color: #2dabe2;
  }
  .display {
    display: none;
  }
  .cardDetailsFacture {
    position: relative;
    background-color: #ededed !important;
    color: #858283;
    .card-body {
      width: 1758px;
    }
    p {
      color: #2dabe2;
      margin-bottom: 0px;
      font-size: 14px;
      width: 134px;
    }
  }
  .icon-plus {
    font-size: 13px;
    color: #8d8cb7;
    &:hover {
      color: #4d4bac;
    }
  }
  .caret-down {
    font-size: 16px;
    color: #8d8cb7;
    &:hover {
      color: #4d4bac;
    }
  }
  .border-botom {
    border-bottom: 1px solid #f6f5fb;
  }
  .details-num-dossier {
    border: 1px solid #f6f5fb;
    width: 60%;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 3%;
    background-color: #f0f1ff;
    .num-dossier {
      border-bottom: 1px solid #f6f5fb;
      border-right: 1px solid #f6f5fb;
    }
  }
  .container {
    height: 28px;
    width: 28px;
    padding: 2px;
    .file-wrapper {
      text-align: center;
      width: 100%;
      height: 100%;
      vertical-align: middle;
      position: relative;
      overflow: hidden;
      border: 1px solid #6c757d;
      border-radius: 50%;
      color: #6c757d;
      background-color: transparent;
      &:hover {
        background-color: #6c757d;
        border-color: white;
        color: white;
      }
      input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 5;
      }
      .display {
        display: none;
      }
      .upload_label {
        font-size: 14px;
        p {
          font-size: 9px;
        }
      }
    }
  }
  .body-task {
    margin-left: 26px;
    position: sticky;
    left: 5px;
    width: 1000px;
    span {
      text-align: start;
      min-width: 172px;
    }
    .span_num {
      width: 20%;
    }
  }
}
</style>
